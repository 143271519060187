<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">套票資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12">
              <form-button-upload
                :fileValue.sync="formData.cover"
                label="套票封面"
                ref="uploadImage"
                inputRef="imgFile"
                required
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="標題" :fieldValue.sync="formData.name" required />
            </v-col>

            <v-col cols="12" md="6" v-if="id !== null">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="價錢" :fieldValue.sync="formData.price" required type="number" prefix="$" />
            </v-col>

            <v-col cols="12" md="6">
              <form-input
                label="有效日數"
                :fieldValue.sync="formData.expiry"
                required
                type="number"
                isIntegerOnly
                suffix="日"
              />
            </v-col>

            <v-col cols="12">
              <label>描述</label>
              <quill-editor :html.sync="formData.description" editorID="editor" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <div class="d-flex align-center">
            <v-card-title class="page-title">換領券</v-card-title>
            <v-btn depressed color="primary accent-4" class="white--text" @click="addCoupon()">新增</v-btn>
          </div>

          <datatable
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            disableFooter
            disablePagination
            disableShadow
            @view-clicked="viewCoupon($event)"
            @delete-clicked="deleteCoupon($event)"
          />
        </v-card-text>
      </v-card>

      <div class="d-flex align-center">
        <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
          儲存
        </v-btn>
        <v-btn v-if="id !== null" depressed color="error accent-4 ml-4" class="white--text" type="button" :loading="$store.getters.isLoading" @click="handleDelete()">
          刪除
        </v-btn>
      </div>
    </v-form>

    <form-dialog
      ref="formCouponDialog"
      formRef="formCoupon"
      title="換領券資料"
      :maxWidth="1000"
      @submit-clicked="updateCoupon($event)"
    >
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <form-input label="名稱" :fieldValue.sync="editCouponData.name" required />
        </v-col>

        <v-col cols="12" md="6">
          <form-input
            label="數量"
            :fieldValue.sync="editCouponData.amount"
            required
            type="number"
            isIntegerOnly
            :customRules="[v => ($validate.isNumber(v) && parseFloat(v) > 0) || '數量必須大於0']"
          />
        </v-col>

        <v-col cols="12">
          <form-button-upload
            :fileValue.sync="editCouponData.cover"
            label="換領券封面"
            ref="uploadCouponCover"
            inputRef="couponCoverFile"
          />
        </v-col>

        <v-col cols="12">
          <form-button-upload
            :fileValue.sync="editCouponData.icon"
            label="換領券Icon"
            ref="uploadCouponIcon"
            inputRef="couponIconFile"
          />
        </v-col>

        <v-col cols="12">
          <form-textarea :fieldValue.sync="editCouponData.terms_conditions" label="使用條款及細則" :rows="5" />
        </v-col>
      </v-row>
    </form-dialog>

    <confirm-dialog ref="confirmDeleteDialog" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import QuillEditor from '@/components/form/QuillEditor.vue'
import Datatable from '@/components/Datatable.vue'
import FormDialog from '@/components/FormDialog.vue'
import FormTextarea from '@/components/form/FormTextarea.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'PackageDetail',
  components: {
    FormInput,
    FormSelect,
    FormButtonUpload,
    QuillEditor,
    Datatable,
    FormDialog,
    FormTextarea,
    ConfirmDialog
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      cover: '',
      name: '',
      price: '',
      expiry: '',
      description: '',
      status: 'active',
    },

    tableHeaders: [
      { text: '名稱', value: 'name' },
      { text: '數量', value: 'amount' },
      { text: '', value: 'actionViewDelete', align: 'end' },
    ],
    tableData: [],

    editCouponData: {
      name: '',
      cover: '',
      icon: '',
      amount: '',
      terms_conditions: '',
    },

    removeCouponIds: [],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetPackageById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.item) {
          this.tableData = data.item.map(item => {
            return {
              id: item.coupon_data.id,
              exist_id: item.coupon_data.id,
              name: item.name,
              amount: item.qty,
              cover: item.coupon_data.cover,
              icon: item.coupon_data.icon,
              terms_conditions: item.coupon_data.terms_conditions,
            }
          })
        }

        this.$common.log(`---------> Table Init`)
        this.$common.log(this.tableData)
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'Package',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'Package',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    addCoupon() {
      if (this.$store.getters.isLoading) {
        return
      }

      for (const key in this.editCouponData) {
        this.$set(this.editCouponData, key, '')
      }

      this.$refs['formCouponDialog'].show()
    },

    viewCoupon(row_id) {
      if (this.$store.getters.isLoading) {
        return
      }

      const found = this.tableData.find(el => el.id === row_id)
      for (const key in this.editCouponData) {
        if (this.$validate.DataValid(found[key])) {
          this.$set(this.editCouponData, key, found[key])
        } else {
          this.$set(this.editCouponData, key, '')
        }
      }
      this.$refs['formCouponDialog'].setEditId(row_id)
      this.$refs['formCouponDialog'].show()
    },

    updateCoupon(row_id = null) {
      if (!this.editCouponData.cover) {
        this.$refs['uploadCouponCover'].showError()
        return
      }

      if (row_id !== null) {
        const found = this.tableData.find(el => el.id === row_id)
        for (const key in this.editCouponData) {
          this.$set(found, key, this.editCouponData[key])
        }
      } else {
        const obj = {
          id: 0,
          exist_id: -1,
          name: this.editCouponData.name,
          amount: this.editCouponData.amount,
          cover: this.editCouponData.cover,
          icon: this.editCouponData.icon,
          terms_conditions: this.editCouponData.terms_conditions,
        }
        if (this.tableData.length > 0) {
          const sortIds = this.tableData.map(el => el.id).sort((a, b) => b - a)
          obj.id = sortIds[0] + 1
        }

        this.tableData.push(obj)
      }

      this.$refs['formCouponDialog'].hide()
      this.$common.log(`---------> Table`)
      this.$common.log(this.tableData)
    },

    deleteCoupon(row_id) {
      if (this.$store.getters.isLoading) {
        return
      }
      const pos = this.tableData.findIndex(el => el.id === row_id)
      if (pos > -1) {
        if (this.tableData[pos].exist_id > -1) {
          this.removeCouponIds.push(this.tableData[pos].exist_id)
        }

        this.tableData.splice(pos, 1)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })

        if (!this.formData.cover) {
          this.$refs['uploadImage'].showError()
        }
        return
      }

      if (this.tableData.length < 1) {
        this.$store.dispatch('toggleSnack', {
          type: 'error',
          message: '至少新增一張換領券',
        })
        return
      }
      this.$store.dispatch('setLoading', true);
      let payload = {
        name: this.formData.name,
        cover: this.formData.cover,
        description: this.formData.description,
        expiry: parseInt(this.formData.expiry),
        price: parseFloat(this.formData.price),
        status: this.formData.status,
        item: [],
      }

      if (this.removeCouponIds.length) {
        payload['remove_coupon'] = this.removeCouponIds
      }

      payload.item = this.tableData.map(item => {
        let obj = {
          name: item.name,
          cover: item.cover,
          icon: item.icon,
          amount: parseInt(item.amount),
          terms_conditions: item.terms_conditions,
        }

        if (item.exist_id > -1) {
          obj['id'] = item.exist_id
        }

        return obj
      })

      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        try {
          await this.$Fetcher.UpdatePackage(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewPackage(payload, user.id, user.token)

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'Package',
          })
        } catch (err) {
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async handleDelete() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }
      
      const confirm = await this.$refs['confirmDeleteDialog'].show();
      if (confirm) {
        this.$store.dispatch('setLoading', true);
        try {
          const user = this.getCurrentUserData()
          await this.$Fetcher.DeletePackage(this.id, user.id, user.token)

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '刪除成功',
            redirect: 'Package',
          })
        } catch (err) {
          this.setAlert({
            title: '刪除失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }

    }
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
