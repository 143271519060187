<template>
  <Dialog
    :open="open"
    :maxWidth="maxWidth"
    :title="title"
    :type="null"
    :loading="formLoading"
    eager
    @close="_dialogAction($event)"
    enableCancel
  >
    <template #content>
      <v-form class="mt-4" :ref="formRef" @submit.prevent>
        <slot></slot>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/layout/Dialog.vue'
export default {
  name: 'FormDialog',
  components: {
    Dialog,
  },
  props: {
    formRef: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    cardContentClass: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    open: false,
    formLoading: false,
    editId: null,
  }),
  methods: {
    show() {
      this.open = true
    },
    hide() {
      this.open = false
      this.formLoading = false
      this.setEditId(null)
    },
    setLoading(loading) {
      this.formLoading = loading
    },
    setEditId(id) {
      this.editId = id
    },
    getEditId() {
      return this.editId
    },

    async _dialogAction(action) {
      if (this.formLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      if (action === true) {
        const valid = await this.$refs[this.formRef].validate()
        if (!valid) {
          this.$store.dispatch('toggleSnack', {
            message: 'required',
          })
          return
        }

        // this.formLoading = true
        this.$emit('submit-clicked', this.editId)
      } else {
        this.hide()
      }
    },
  },
}
</script>
