<template>
  <v-row>
    <v-file-input
      v-show="false"
      v-model="fileUploaded"
      :ref="inputRef"
      :label="label"
      hide-details="auto"
      persistent-placeholder
      outlined
      @change="_onFilePicked"
      :accept="acceptType"
      :disabled="isDisabled"
      :multiple="multiple"
    />
    <v-col cols="12" v-if="multiple">
      <draggable
        v-model="fileList"
        animation="200"
        @change="_updateFileList()"
        tag="div"
        handle=".canDrag"
        :disabled="$store.getters.isUploading || $store.getters.isLoading"
      >
        <transition-group class="multi-upload-display" type="transition" name="child" tag="div">
          <div class="multi-upload-display__item canDrag" v-for="(item, index) in fileList" :key="item.id">
            <div>
              <template v-if="item.file != ''">
                <div
                  v-if="_type(item.file) == 'image'"
                  @click="_viewFile(item.id)"
                  class="multi-upload-display__preview"
                  :style="
                    `background-image:url(${getFile(
                      item.file,
                    )}); background-size: contain; background-position: center;`
                  "
                ></div>
                <div v-else @click="_viewFile(item.id)" class="multi-upload-display__preview">
                  <v-icon size="50">{{ icon.mdiFileDocument }}</v-icon>
                </div>
              </template>

              <span class="multi-upload-display__item-count">{{ index + 1 }}</span>
              <v-btn
                fab
                small
                depressed
                color="error"
                class="multi-upload-display__item-delete"
                @click.prevent="_removeImg(index)"
                ><v-icon color="white" size="15">{{ icon.mdiWindowClose }}</v-icon></v-btn
              >
            </div>
          </div>

          <div
            class="multi-upload-display__item uploading"
            v-for="(item, index) in uploadingList"
            :key="`uploading-${index}`"
            v-if="!item.uploaded"
          >
            <div>
              <template v-if="item.file">
                <div v-if="_type(item.file.name) == 'image'" class="multi-upload-display__preview">
                  <v-img class="h-full" :src="item.fileBase"></v-img>
                </div>
                <div v-else class="multi-upload-display__preview">
                  <v-icon size="50">{{ icon.mdiFileDocument }}</v-icon>
                </div>
              </template>

              <v-progress-circular
                class="ma-1"
                :rotate="-90"
                :size="$vuetify.breakpoint.width < 680 ? 30 : $vuetify.breakpoint.width < 1028 ? 38 : 48"
                :width="6"
                :value="item.progress"
                :color="item.error ? 'error' : 'success'"
                ><v-icon>{{ item.error ? icon.mdiCloseCircle : icon.mdiUpload }}</v-icon></v-progress-circular
              >
            </div>
          </div>
        </transition-group>
      </draggable>
    </v-col>

    <v-col v-if="singleImageType" cols="12" md="6" lg="3">
      <div class="d-md-none d-block mb-3">{{ label }}{{ uploadRemarks }}</div>
      <v-img
        ref="imgPreview"
        class="img-preview w-full"
        :src="getFile(fileValue)"
        max-width="250"
        max-height="250"
        contain
      />
    </v-col>

    <v-col cols="12" :md="singleImageType ? '6' : '12'" :lg="singleImageType ? '9' : '12'" class="d-flex">
      <v-btn
        v-if="multiple || (!multiple && !fileValue)"
        icon
        class="icon-btn"
        :class="{ docUpload: fileType === 'docOnly' }"
        @click="_handleFileImport()"
        :disabled="isDisabled"
      >
        <v-img
          class="ma-5"
          contain
          :src="icon.defaultImage"
          :width="fileType === 'docOnly' ? 30 : 50"
          :height="fileType === 'docOnly' ? 30 : 50"
        ></v-img>
      </v-btn>

      <v-btn
        v-if="!multiple && fileValue && _type(fileValue) !== 'image'"
        icon
        :href="getFile(fileValue)"
        target="_blank"
        ><v-icon size="35">{{ icon.mdiFileDocument }}</v-icon></v-btn
      >

      <div class="mx-4 align-self-center d-md-block" :class="{ 'd-none': !multiple && _type(fileValue) == 'image' }">
        {{ label }}{{ uploadRemarks }}
      </div>
      <v-btn
        class="ml-2 align-self-center text-decoration-underline"
        icon
        @click="_handleFileImport()"
        :disabled="isDisabled"
        plain
        :loading="$store.getters.isUploading"
        >上載</v-btn
      >

      <template v-if="!multiple && fileValue">
        <v-btn
          class="ml-2 align-self-center text-decoration-underline"
          icon
          :disabled="isDisabled"
          :href="getFile(fileValue)"
          target="_blank"
          plain
          >查看</v-btn
        >
        <v-btn
          class="ml-2 align-self-center text-decoration-underline error--text"
          icon
          :disabled="isDisabled"
          plain
          @click="_removeImg()"
          >刪除</v-btn
        >
      </template>
    </v-col>
    <v-col cols="12" style="max-width: 500px;">
      <v-text-field
        outlined
        :value="fileValue"
        :rules="[v => !required || $validate.DataValid(v, multiple)]"
        v-show="false"
      ></v-text-field>
      <v-alert
        v-for="error in uploadingErrors"
        class="mt-1"
        show
        :icon="icon.mdiAlertCircleOutline"
        rounded
        dense
        type="error"
        transition="scroll-y-transition"
      >
        <span class="text-subtitle-2">{{ error }}</span>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import defaultImage from '@/assets/images/upload_photo.svg'
import {
  mdiFileDocument,
  mdiAlertCircleOutline,
  mdiWindowClose,
  mdiEyeOutline,
  mdiCloseCircle,
  mdiUpload,
} from '@mdi/js'
import draggable from 'vuedraggable'

export default {
  name: 'FormButtonUpload',
  components: {
    draggable,
  },
  props: {
    inputRef: {
      type: String,
      default: 'image',
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    fileValue: {
      type: String | Array,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'imgOnly', // 'imgOnly' | 'docOnly' | 'imgWithPDF'
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: -1,
    },
  },
  setup() {
    return {
      icon: {
        defaultImage,
        mdiFileDocument,
        mdiAlertCircleOutline,
        mdiWindowClose,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiUpload,
      },
    }
  },
  computed: {
    isDisabled() {
      return this.$store.getters.isUploading || this.disabled
    },
    acceptType() {
      switch (this.fileType) {
        case 'docOnly':
          return '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
        case 'imgWithPDF':
          return '.pdf,.jpg,.jpeg,.png,application/pdf,image/jpeg,image/pjpeg,image/png,image/png,image/*;capture=camera'
        case 'imgOnly':
        default:
          return '.jpg,.jpeg,.png,image/jpeg,image/pjpeg,image/png,image/png,image/*;capture=camera'
      }
    },

    uploadRemarks() {
      switch (this.fileType) {
        case 'docOnly':
          return ' (PDF,DOC,XLS,PPT / 最大10MB)'
        case 'imgWithPDF':
          return ' (JPG,PNG,PDF / 最大8/10MB)'
        case 'imgOnly':
        default:
          return ' (JPG,PNG / 最大8MB)'
      }
    },
    singleImageType() {
      return !this.multiple && this._type(this.fileValue) === 'image'
    },
  },
  watch: {
    fileValue: {
      handler(list) {
        if (this.firstInit) {
          if (Array.isArray(list)) {
            this.fileList = []
            for (let i = 0; i < list.length; i++) {
              this.fileList.push({
                id: i,
                file: list[i],
              })
            }
          }
        }
        this.firstInit = false
      },
      deep: true,
    },
  },
  data() {
    return {
      firstInit: true,
      fileUploaded: null,
      fileList: [],
      uploadingList: [],
      uploadingErrors: [],
    }
  },
  methods: {
    getFile(file) {
      return this.$mediaPath + file
    },

    _type(file) {
      if (!file) {
        return null
      }

      const fileExt = file
        .split('.')
        .pop()
        .toLowerCase()
      if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png') {
        return 'image'
      } else {
        return 'doc'
      }
    },

    _viewFile(index) {
      if (this.fileList[index]) {
        window.open(this.getFile(this.fileList[index].file), '_blank')
      }
    },

    _removeImg(index) {
      this.fileUploaded = null
      if (this.multiple) {
        this.fileList.splice(index, 1)
        this._updateFileList()
      } else {
        this.$emit('update:fileValue', '')
      }
    },

    _updateFileList() {
      const list = this.fileList.map(item => item.file)
      this.$emit('update:fileValue', list)
    },

    _handleFileImport() {
      if (this.$store.getters.isUploading || this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      if (this.multiple && this.max > -1 && this.fileValue.length >= this.max) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          type: 'error',
          message: '檔案數量已達上限',
        })
        return
      }
      this.$refs[this.inputRef].$refs.input.click()
    },

    showError(msg) {
      const alertMsg = msg || '請上載檔案'
      this.uploadingErrors = [alertMsg]
    },

    async _onFilePicked(file) {
      this.uploadingList = []
      this.uploadingErrors = []

      if (!file || (Array.isArray(file) && file.length < 1)) {
        return
      }

      if (this.multiple && this.max > -1 && this.fileValue.length + file.length > this.max) {
        this.showError(`所選檔案數量已超出上限（剩餘數量：${this.max - this.fileValue.length}）`)
        return
      }

      if (Array.isArray(file)) {
        // multiple
        let promises = []
        for (let i = 0; i < file.length; i++) {
          const targetFile = file[i]
          const valid = await this.$validate.validateFileInput(targetFile, this.acceptType)
          if (valid !== true) {
            this.uploadingErrors.push(`${valid} (${targetFile.name})`)
          }

          let filePromise = new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(targetFile)
            reader.onload = e =>
              resolve({
                fileBase: e.target.result,
                file: targetFile,
                progress: valid !== true ? 100 : 0,
                error: valid !== true ? true : false,
                uploaded: false,
              })
          })
          promises.push(filePromise)
        }

        Promise.all(promises).then(async list => {
          this.uploadingList = [...list]
          for (let i = 0; i < this.uploadingList.length; i++) {
            if (!this.uploadingList[i].error) {
              const uploaded = await this._processUpload(this.uploadingList[i].file, this.uploadingList[i])
              if (uploaded !== true) {
                this.uploadingList[i].error = true
                this.uploadingErrors.push(`${uploaded} (${this.uploadingList[i].file.name})`)
              } else {
                this.uploadingList[i].uploaded = true
              }
            }
          }
        })
      } else {
        // single file
        const valid = await this.$validate.validateFileInput(file, this.acceptType)
        if (valid !== true) {
          this.showError(valid)
          return
        }

        const uploaded = await this._processUpload(file)
        if (uploaded === true) {
          this.$store.dispatch('toggleSnack', {
            show: true,
            type: 'success',
            message: '上傳成功',
          })
        } else {
          this.showError(uploaded)
        }
      }
    },

    _processUpload(file, uploadItem) {
      this.$store.dispatch('setUploadLoading', true)

      const user = this.getCurrentUserData()
      let payload = {
        name: file.name,
        user_token: user.token,
        verify_id: user.id,
      }

      if (file.type.indexOf('image') > -1) {
        payload['forceJPG'] = true
      } else if (file.type.indexOf('application') > -1) {
      } else {
        this.$store.dispatch('setUploadLoading', false)
        this.showError('不支援檔案格式')
        return '不支援檔案格式'
      }

      const uploaded = this.$XHR
        .post(
          {
            file: file,
            upload_image: JSON.stringify(payload),
          },
          progress => {
            if (uploadItem) {
              uploadItem.progress = this._formatUploadProgress(progress.loaded, progress.total)
            } else {
              this.$store.dispatch('toggleSnack', {
                show: true,
                type: 'processing',
                message: `上傳中... (${this._formatUploadProgress(progress.loaded, progress.total)}%)`,
                closeManual: true,
              })
            }
          },
        )
        .then(newFile => {
          this.$store.dispatch('setUploadLoading', false)

          if (this.multiple) {
            const list = this.$common.duplicateData(this.fileValue)
            list.push(newFile)
            this.fileList.push({
              id: this.fileList.length,
              file: newFile,
            })
            this.$emit('update:fileValue', list)
          } else {
            this.$emit('update:fileValue', newFile)
          }

          return true
        })
        .catch(error => {
          this.$common.error(error);
          this.$store.dispatch('setUploadLoading', false)
          this.$store.dispatch('toggleSnack')
          let msg = '上傳失敗'
          switch (error) {
            case 'noPermission':
              msg = '你沒有權限進行此動作'
              break
            case 'tokenExpired':
              msg = '登入過期，請重新登入'
              break
          }

          return msg
        })

      return uploaded
    },

    _formatUploadProgress(value, total) {
      return parseInt(((value / total) * 100).toFixed(2))
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-btn {
  height: 80px !important;
  width: 80px !important;

  &.docUpload {
    height: 40px !important;
    width: 40px !important;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
.img-preview {
  background-color: #e0e0e0 !important;
}

.multi-upload-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > .multi-upload-display__item {
    width: 15%;
    width: calc((100% - 15px * 5) / 6);
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: grab;
    background-color: #e0e0e0;
    border-radius: 15px;

    &:not(:nth-of-type(6n)) {
      margin-right: 15px;
    }

    &.uploading {
      cursor: default;
      & > .multi-upload-display__preview {
        opacity: 0.8;
      }
    }

    & > div {
      width: 100%;
      padding: 100% 0 0 0;
      position: relative;

      & > .multi-upload-display__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > .multi-upload-display__item-count {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 16px;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        color: #fff;
        font-weight: 700;
        z-index: 2;
        background-color: #5f5a8b;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-15%, -15%);
      }

      & > .multi-upload-display__item-delete,
      & > .multi-upload-display__item-view {
        position: absolute;
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        transform: translate(15%, -15%);
        z-index: 2;
      }

      & > .multi-upload-display__item-view {
        right: 40px;
      }

      & > .v-progress-circular {
        position: absolute;
        bottom: 5px;
        left: 5px;
      }
    }
  }
}

@media (max-width: 1028px) {
  .multi-upload-display {
    & > .multi-upload-display__item {
      width: 23%;
      width: calc((100% - 15px * 3) / 4);

      &:not(:nth-of-type(6n)) {
        margin-right: 0;
      }
      &:not(:nth-of-type(4n)) {
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: 680px) {
  .multi-upload-display {
    & > .multi-upload-display__item {
      width: 30%;
      width: calc((100% - 10px * 2) / 3);

      &:not(:nth-of-type(4n)) {
        margin-right: 0;
      }
      &:not(:nth-of-type(3n)) {
        margin-right: 10px;
      }

      & > div {
        & > .multi-upload-display__preview {
          border-radius: 10px;
        }

        & > .multi-upload-display__item-count,
        & > .multi-upload-display__item-delete,
        & > .multi-upload-display__item-view {
          width: 25px;
          height: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
