<template>
  <v-container fluid class="pa-0 ma-0">
    <v-tooltip v-model="showtip" top :nudge-left="offset" :open-on-hover="false" max-width="220">
      <template v-slot:activator="{ on }">
        <vue-editor
          :id="editorID"
          ref="editor"
          v-model="editorText"
          retain-focus-on-click
          use-custom-image-handler
          :editor-options="getEditorOptions"
          @blur.native="on.blur"
          @image-added="addEditorImage"
          @text-change="onTextChange"
        ></vue-editor>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>

    <v-alert
      v-show="uploading"
      class="mt-3"
      :icon="mdiInformationOutline"
      rounded
      dense
      type="success"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'uploadingFileInfo' + ' (' + progress + '%)' }}</span>
    </v-alert>

    <v-alert
      v-show="msgTextLimit"
      class="mt-3"
      :icon="mdiAlertOutline"
      rounded
      dense
      type="warning"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'editorLimit' }}</span>
    </v-alert>
    <v-alert
      v-show="msgUrlInvalid"
      class="mt-3"
      :icon="mdiAlertOutline"
      rounded
      dense
      type="warning"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'editorURLinvalid' }}</span>
    </v-alert>
  </v-container>
</template>

<script>
import { mdiAlertOutline, mdiInformationOutline } from '@mdi/js'

import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'
import { Quill } from 'vue2-editor' // Hook into Quill's API for Custom Functionality
import ImageResize from 'quill-image-resize'

// :attach="tipParent"

Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'QuillEditor',
  components: { VueEditor },
  props: {
    html: {
        type: String,
        required: true,
        default: ''
    },
    editorID: {
        type: String,
        required: false,
        default: 'editor'
    }
  },
  data: () => {
    return {
      editorSettings: {
        modules: {
          toolbar: {
            container: [
              [{ undo: 'undo' }, { redo: 'redo' }], // custom redo/undo button
              [{ header: [1, 2, 3, 4, false] }],
              ['bold', 'italic', 'underline', 'strike', { script: 'sub' }, { script: 'super' }],
              [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
              [{ list: 'bullet' }, { list: 'ordered' }, 'blockquote', 'code-block'],

              // ['link', 'image', 'video', 'formula'],
              ['link', 'image'],
              ['clean'],
            ],
            handlers: {
              redo() {
                this.quill.history.redo()
              },
              undo() {
                this.quill.history.undo()
              },
            },
          },
          history: {
            delay: 2000,
            maxStack: 100,
            userOnly: true,
          },
          imageResize: {
            modules: ['Resize', 'DisplaySize'],
          },
        },
        theme: 'snow',
      },
      showtip: false,
      tipParent: null,
      tipCount: 0,
      tooltipText: '',
      offset: 0,
      progress: 0,
      uploading: false,

      msgTextLimit: false,
      msgUrlInvalid: false,

      mdiInformationOutline,
      mdiAlertOutline,


      editorText: '',
    }
  },
  watch: {
    html: {
        handler(val) {
            this.editorText = val;
        },
        immediate: true,
        deep: true
    }
  },

  computed: {
    getEditorOptions() {
      const options = this.editorSettings
      options.bounds = `#${this.editorID}`

      return options
    },
  },

  methods: {
    setSanitizer() {
      // validate and sanitize URL
      const vm = this
      const { save } = this.$refs.editor.quill.theme.tooltip
      this.$refs.editor.quill.theme.tooltip.save = function() {
        // overwrite save link functionality

        let url = this.textbox.value
        const mode = this.textbox.parentNode.getAttribute('data-mode')

        // force HTTPS
        if (url.indexOf('http') != 0) {
          url = `https://${url}`
        } else if (url.indexOf('http://') == 0) {
          url = `https://${url.substring(7)}`
        }

        // validate url
        if (mode == 'video' ? vm.RegExTester.urlYoutube(url) : vm.RegExTester.urlFull(url)) {
          this.textbox.value = url
          save.call(this)
        } else {
          // show error in tooltip
          vm.msgUrlInvalid = true
          setTimeout(() => {
            vm.msgUrlInvalid = false
          }, 5000)
        }
      }
    },

    setFormatter() {
      // get rid of everything (images, iframes, etc.) but plain text
      this.$refs.editor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        const ops = []
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({ insert: op.insert })
          }
        })
        delta.ops = ops

        return delta
      })
    },
    setPlaceholder() {
      const input = document.querySelector('input[data-link]')
      input.dataset.link = 'https://www.google.com'
      input.dataset.video = 'https://youtu.be/lepYkDZ62OY'
      input.placeholder = 'https://www.google.com'
    },
    uploadImgExtCheck(name, mimeType, allowed) {
      const fileExt = name
        .split('.')
        .pop()
        .toLowerCase()
      const fileMime = mimeType.substring(6).toLowerCase() // remove 'image/' from MIME type

      return allowed.indexOf(fileExt) >= 0 && allowed.indexOf(fileMime) >= 0
    },
    async addEditorImage(file, Editor, cursorLocation, resetUploader) {
      if (file) {
        const check = this.$validate.validateFileInput(file, ['png', 'jpeg', 'jpg'])
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('setUploadLoading', false)
          this.$store.dispatch('controlUploadAlert', { show: true, progress: 0 })

          this.$XHR
            .post(
              {
                upload_image: { forceJPG: true },
                file,
              },
              progress => {
                this.$store.dispatch('controlUploadAlert', {
                  show: true,
                  progress: this.$common.formatUploadProgress(progress.loaded, progress.total),
                })
              },
            )
            .then(res => {
              this.$store.dispatch('setUploadLoading', false)
              this.$store.dispatch('controlUploadAlert')
              this.$common.logData(res, '----------> XHR [SUCCESS]: UploadFile')
              if (res.data && res.data.filename) {
                Editor.insertEmbed(cursorLocation, 'image', this.$mediaPath + res.data.filename)
                resetUploader()

                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '上傳成功',
                  type: 'success',
                  refresh: false,
                  redirect: '',
                })
              } else {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '上傳失敗',
                  type: 'error',
                  refresh: false,
                  redirect: '',
                })
              }
            })
            .catch(err => {
              this.$store.dispatch('setUploadLoading', false)
              this.$store.dispatch('controlUploadAlert')
              this.$common.error(err)
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '上傳失敗',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            })
        }
      }
    },
    onTextChange(delta, old, source) {
      const { quill } = this.$refs.editor
      if (quill.getLength() < 0) {
        // plus 12 for overflow
        this.msgTextLimit = true
        quill.deleteText(1612, quill.getLength())
      } else {
        this.msgTextLimit = false
      }
      this.$emit('update:html', this.editorText)
    },
  },

  created() {
    const icons = Quill.import('ui/icons')
    icons.undo =
      '<svg viewBox="0 0 24 24"><path class="ql-fill" d="M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z" /></svg>'
    icons.redo =
      '<svg viewBox="0 0 24 24"><path class="ql-fill" d="M10.5 18H18V20H10.5C6.91 20 4 17.09 4 13.5S6.91 7 10.5 7H16.17L13.08 3.91L14.5 2.5L20 8L14.5 13.5L13.09 12.09L16.17 9H10.5C8 9 6 11 6 13.5S8 18 10.5 18Z" /></svg>'
  },

  mounted() {
    this.setFormatter()
    this.setPlaceholder()
    this.tipParent = document.querySelector(`#${this.editorID}`)
  },
}
</script>

<style>
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '標題1' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '標題2' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '標題3' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '標題4' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '標題5' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '標題6' !important;
}

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '正文' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip::before {
  content: '瀏覽URL:' !important;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'URL (with HTTPS):' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'URL (需支援HTTPS):' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '儲存' !important;
  padding-right: 0px;
}

:lang(zh-HK) .ql-snow .ql-tooltip a.ql-action::after {
  content: '編輯';
}

:lang(zh-HK) .ql-snow .ql-tooltip a.ql-remove::before {
  content: '移除';
}

.ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'YouTube Video URL:' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'YouTube影片網址:' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='formula']::before {
  content: '請輸入公式:' !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #d73094;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #d73094;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #d73094;
}

.ql-snow .ql-tooltip {
  border-radius: 0.5rem;
}

body.dark .ql-snow .ql-tooltip {
  background-color: #000;
  color: #fff;
}

body.dark .ql-snow input {
  color: #fff;
}

body.dark .ql-snow ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaa;
}

body.dark .ql-snow ::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaa;
}

body.dark .ql-snow :-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
}

body.dark .ql-snow :-moz-placeholder {
  /* Firefox 18- */
  color: #aaa;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-right: 12px;
  padding-bottom: 2px;
  border-right: 1px solid #999;
}

body.dark .ql-toolbar.ql-snow .ql-formats {
  border-color: #aaa;
}

.ql-editor {
  overflow: visible;
}

.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 0.5rem 0.5rem;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #666;
}

body.dark .ql-snow .ql-picker {
  color: #ddd;
}

body.dark .ql-snow .ql-picker-options {
  background-color: #000;
}

body.dark .quillWrapper .ql-snow .ql-stroke {
  stroke: rgba(242, 242, 242, 0.9);
}

body.dark .quillWrapper .ql-snow .ql-fill {
  fill: rgba(242, 242, 242, 0.9);
}
</style>
